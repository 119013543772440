import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/getting-started"
        }}>{`Getting Started`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/upgrading"
        }}>{`Upgrade from pre-1.0.0`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/auth0"
        }}>{`Use with Auth0`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/netlify-identity"
        }}>{`Use with Netlify Identity`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/roles"
        }}>{`Role/scope permissions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/auth-providers"
        }}>{`Create an auth provider`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/api-reference"
        }}>{`API Reference`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      