// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-auth-0-callback-js": () => import("./../../../src/pages/auth0_callback.js" /* webpackChunkName: "component---src-pages-auth-0-callback-js" */),
  "component---src-pages-docs-api-reference-mdx": () => import("./../../../src/pages/docs/api-reference.mdx" /* webpackChunkName: "component---src-pages-docs-api-reference-mdx" */),
  "component---src-pages-docs-auth-0-mdx": () => import("./../../../src/pages/docs/auth0.mdx" /* webpackChunkName: "component---src-pages-docs-auth-0-mdx" */),
  "component---src-pages-docs-auth-mdx": () => import("./../../../src/pages/docs/auth.mdx" /* webpackChunkName: "component---src-pages-docs-auth-mdx" */),
  "component---src-pages-docs-auth-providers-mdx": () => import("./../../../src/pages/docs/auth-providers.mdx" /* webpackChunkName: "component---src-pages-docs-auth-providers-mdx" */),
  "component---src-pages-docs-callback-mdx": () => import("./../../../src/pages/docs/callback.mdx" /* webpackChunkName: "component---src-pages-docs-callback-mdx" */),
  "component---src-pages-docs-enjoy-mdx": () => import("./../../../src/pages/docs/enjoy.mdx" /* webpackChunkName: "component---src-pages-docs-enjoy-mdx" */),
  "component---src-pages-docs-getting-started-mdx": () => import("./../../../src/pages/docs/getting-started.mdx" /* webpackChunkName: "component---src-pages-docs-getting-started-mdx" */),
  "component---src-pages-docs-netlify-identity-mdx": () => import("./../../../src/pages/docs/netlify-identity.mdx" /* webpackChunkName: "component---src-pages-docs-netlify-identity-mdx" */),
  "component---src-pages-docs-roles-auth-0-mdx": () => import("./../../../src/pages/docs/roles-auth0.mdx" /* webpackChunkName: "component---src-pages-docs-roles-auth-0-mdx" */),
  "component---src-pages-docs-roles-mdx": () => import("./../../../src/pages/docs/roles.mdx" /* webpackChunkName: "component---src-pages-docs-roles-mdx" */),
  "component---src-pages-docs-roles-netlify-identity-mdx": () => import("./../../../src/pages/docs/roles-netlify-identity.mdx" /* webpackChunkName: "component---src-pages-docs-roles-netlify-identity-mdx" */),
  "component---src-pages-docs-upgrading-mdx": () => import("./../../../src/pages/docs/upgrading.mdx" /* webpackChunkName: "component---src-pages-docs-upgrading-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-introduction-mdx": () => import("./../../../src/pages/Introduction.mdx" /* webpackChunkName: "component---src-pages-introduction-mdx" */)
}

